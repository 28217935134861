import React from 'react'
import { Route, Switch } from 'react-router-dom'

// screens
import Contact from './views/Contact'
import About from './views/About'
import Login from './views/Login'
import New from './views/CreatePost'
import Post from './views/Post'
import Posts from './views/Posts'
import UserProfile from './views/UserProfile'
import QuickPost from './views/QuickPost'
import ProfileNew from './views/ProfileNew'
import Privacy from './views/Privacy'
import Terms from './views/Terms'
import Bounce from './views/Bounce'

import './firebase'
// components
import Header from './components/Header'
import Footer from './components/Footer'
import Loading from './components/Loading'

// auth
import { AuthProvider, AuthConsumer } from './auth/AuthContext'

const App = () => {
  return (
    <AuthProvider>
      <AuthConsumer>
        {({ anonymous, user, userName, userLoading, refreshProfile }) => (
          (!userLoading)
            ? (
              <>
                <Header />
                <Switch>
                  {(user && !anonymous) && !userName && <Route path='/' component={ProfileNew} />}
                  <Route exact path='/' component={Posts} />
                  <Route exact path='/contact' component={Contact} />
                  <Route exact path='/about' component={About} />
                  <Route path='/postulation/:postulationId' component={Post} />
                  {(user || anonymous) && <Route path='/quick-post/:postulationId' component={QuickPost} />}
                  <Route exact path='/privacy' component={Privacy} />
                  <Route exact path='/terms' component={Terms} />
                  {user && !anonymous && <Route path='/profile' component={UserProfile} />}
                  {user && !anonymous && <Route path='/add/:sourceId' component={New} />}
                  {user && !anonymous && <Route path='/add' component={New} />}
                  {user && <Route exact path='/bounce' component={Bounce} /> }
                  <Route exact path='/login' component={Login} />
                </Switch>
                <Footer />
              </>
              )
            : <Loading />
        )}
      </AuthConsumer>
    </AuthProvider>
  )
}

export default App

// registerServiceWorker()
