import { useEffect, useState } from 'react'
import { getDatabase, off, onValue, ref, set } from 'firebase/database'
import { getAuth } from 'firebase/auth'
import _get from 'lodash.get'


const useBounce = () => {
  const uid = _get(getAuth(), 'currentUser.uid')
  const refPath = 'bounce'
  const db = getDatabase()
  let bounceRef
  const [data, setData] = useState([])

  useEffect(() => {
    const get = () => {
      bounceRef = ref(db, refPath)
      onValue(bounceRef, (item) => {
        setData(item.val())
      })
    }
    get()

    return () => {
      if (bounceRef) { // close the connection
        off(bounceRef)
      }
    }
  }, [])

  const leave = () => {
    set(ref(db, `bounce/${uid}`), null)
  }

  const setValue = (value) => {
    set(ref(db, `bounce/${uid}`), value)
  }

  return {
    leave,
    set: setValue,
    data
  }
}

export default useBounce
