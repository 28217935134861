import React, { useEffect, useState } from 'react'
import { initializeApp, getApp as getFirebaseApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'

import App from './App'

const config = {
  apiKey: 'AIzaSyDW3VPN2UDSVTN26WXA1GzEdFfLERKg58M',
  authDomain: 'like-hell-it-is.firebaseapp.com',
  databaseURL: 'https://like-hell-it-is.firebaseio.com',
  projectId: 'like-hell-it-is',
  storageBucket: 'like-hell-it-is.appspot.com',
  messagingSenderId: '336657953079'
}

let app

const FirebaseInit = () => {
  const [fbInit, setFbInit] = useState(false)

  setTimeout(() => {
    setFbInit(true)
  }, 1000)

  useEffect(() => {
    initializeApp(config)
    const functions = getFunctions(getFirebaseApp())
    const database = getDatabase(getFirebaseApp())
    // if (window.location.hostname === 'localhost') {
    //   connectFunctionsEmulator(functions, 'localhost', 3000)
    //   connectDatabaseEmulator(database, 'localhost', 9000)
    // }    
  }, [])

  if (!fbInit) {
    return <div>Loading...</div>
  }
  return <App />
}

export default FirebaseInit


export const getApp = () => app
