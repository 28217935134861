import { useEffect, useState } from 'react'
import { getDatabase, off, onValue, ref, serverTimestamp, set } from 'firebase/database'
import { getAuth } from 'firebase/auth'

import _get from 'lodash.get'
import _forOwn from 'lodash.forown'
import _each from 'lodash.foreach'
import _keys from 'lodash.keys'

const convertToRanks = (voteArray, omits = {}) => {
  const saveRanks = {}
  _each(voteArray, (vote, i) => {
    saveRanks[vote.id] = { idx: i }
  })
  _each(_keys(omits), omitKey => {
    saveRanks[omitKey] = { idx: -1 } // omit candidates get an index of -1 ?
  })
  return saveRanks
}

const usePostUserVote = ({ isQuick = false, postId }) => {
  const uid = _get(getAuth(), 'currentUser.uid')
  const refPath = isQuick ? `quickVotes/${postId}/${uid}` : `votes/${postId}/${uid}`

  const [vote, setVote] = useState()
  const [loading, setLoading] = useState(true)

  const db = getDatabase()

  let postVoteRef

  useEffect(() => {
    const get = () => {
      setVote() // in case the postId changes, the vote must be nullified
      postVoteRef = ref(db, refPath)
      onValue(postVoteRef, (item) => {
        if (item.val()) {
          setVote({
            ...item.val(),
            id: postId,
          })
        }
        setLoading(false)
      })
    }
    get()

    return () => {
      if (postVoteRef) { // close the connection
        off(postVoteRef)
      }
    }
  }, [postId])

  const saveVote = (userVote, isAbstain = false) => {
    const myVoteRef = ref(getDatabase(), refPath)

    const voteData = {
      ranks: convertToRanks(userVote),
      createdAt: serverTimestamp(),
      abstained: false
    }

    return set(myVoteRef, voteData)
  }

  const abstain = (userVote) => {
    return saveVote(userVote, true)
  }

  return {
    vote,
    loading,
    saveVote,
    abstain
  }
}

export default usePostUserVote
