import React, { useEffect, useState } from 'react'

import useBounce from '../hooks/useBounce'

const Bounce = () => {
  const [position, setPosition] = useState(0)
  const [joined, setJoined] = useState(false)

  const { set, leave, data } = useBounce()

  useEffect(() => {
    if (joined) {
      setTimeout(() => { 
        setPosition(position > 800 ? 0 : position + 5)
        set(position > 800 ? 0 : position + 5)
      }, 100)
    }
    // return () => {
    //   setJoined(false)
    //   leave()
    // }
  }, [position, joined])

  const start = () => {
    setJoined(true)
  }

  const stop = () => {
    setJoined(false)
    setTimeout(leave, 200)
  }

  const COLORS = [
    'red', 'blue', 'green', 'yellow', 'orange', 'black'
  ]

  return (
  <div>
    <button onClick={start}>Join</button>
    <button onClick={stop}>Leave</button>
    { data && (
    <div>
      {Object.keys(data).map((uid, idx) => <div style={{ backgroundColor: COLORS[idx], height: 40, margin: 10, padding: 10, width: Object.values(data)[idx] }}>{uid}</div>)}
    </div>
    )}
  </div>
  )

}

export default Bounce